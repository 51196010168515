import moment from "moment";
import EditTask from "../editTask";
import { useEffect, useState, useRef } from "react";
import DeleteItem from "../listItem/deleteItem";
import { TaskState } from "../taskChartCommon/taskState";
import { formatTaskDuration } from "../../utils/stringUtils";

const getStreamingStatusClass = (state) => {
  switch (state) {
    case TaskState.PAUSED:
      return "paused";
    case TaskState.RUNNING:
      return "active";
    case TaskState.CREATED:
    case TaskState.COMPLETED:
    default:
      return "inactive"
  }
}

const Task = ({ data, handleDelete }) => {
  const intervalId = useRef(null);
  const [duration, setDuration] = useState(data.duration);

  useEffect(() => {
    switch(data.state) {
      case TaskState.RUNNING:
        if (intervalId.current) {
          clearInterval(intervalId.current);
        }

        intervalId.current = setInterval(() => setDuration((counter) => counter + 1), 1000);
        break;
      default:
        clearInterval(intervalId);
        intervalId.current = null;
    }

    return () => clearInterval(intervalId.current);
  }, [data.state]);

  return (
    <>
      <div className="d-flex justify-content-between">
        <span> {data.title} </span>
        <div className="hover-target d-flex gap-2">
          <EditTask
            item={data}
            extraInfo={{
              subjectId: data.subjectId,
              projectId: data.projectId,
            }}
          />
          <DeleteItem
            handleDeleteItem={() => handleDelete(data.objectId)}
            text="removeTaskConfirmText"
          />
        </div>
      </div>
      <div className="d-flex gap-3 justify-content-space-between align-items-center">
        <div className="d-flex gap-4">
          <span>{moment(data.createdAt).format("YYYY-MM-DD HH:mm:ss")} {data.completedAt ? "~ " + moment(data.completedAt).format("YYYY-MM-DD HH:mm:ss") : ""} </span>
        </div>

        <div className="d-flex gap-3 align-items-center">
          {formatTaskDuration(Math.round(duration || 0))}
          <span className={`streaming-status ${getStreamingStatusClass(data.state)}`}></span>
        </div>
      </div>
    </>
  );
}

export default Task;