import { combineReducers } from "redux";
import logout from "./logout";
import authReducer from "./slices/auth";
import projectsReducer from "./slices/projects";
import subjectReducer from "./slices/subjects";
import taskReducer from "./slices/tasks";
import deviceReducer from "./slices/devices";
import userReducer from "./slices/user";
import alertsReducer from "./slices/alert";
import initialState from "./initialState";
import dashboardReducer from "./slices/dashboard";
import socketReducer from "./slices/socket";

const appReducer = combineReducers({
  auth: authReducer,
  projects: projectsReducer,
  subjects: subjectReducer,
  tasks: taskReducer,
  devices: deviceReducer,
  user: userReducer,
  alerts: alertsReducer,
  dashboard: dashboardReducer,
  socket: socketReducer,
});

const appReducerConditional =  (state, action) => {
  if (action.type === logout.type)
    return appReducer(initialState, action);

  return appReducer(state, action);
};

export default appReducerConditional;