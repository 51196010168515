import { join, startsWith, trim } from "lodash";

function addBaseUrl(config) {
  const customBaseUrl = config.baseUrl;
  const defaultBaseUrl = process.env.REACT_APP_SERVER_URL;

  const baseUrl = customBaseUrl || defaultBaseUrl;

  if (startsWith(config.url, "http://") || startsWith(config.url, "https://")) {
    return config;
  }
  return {
    ...config,
    url: join([trim(baseUrl, "/"), trim(config.url, "/")], "/"),
  };
}

export default addBaseUrl;
