/* eslint-disable no-unused-vars */
import axios from "../../services/httpService";
import * as actions from "../api";

const appendQueryTOGetUrl = (url, data) => {
  const dataArray = Object.entries(data);
  let query = dataArray.reduce(
    (previousValue, currentValue) =>
      `${previousValue}${currentValue[0]}=${currentValue[1]}&`,
    ""
  );
  query = query.substring(0, query.length - 1);
  return url + "?" + query;
};

const api =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type !== actions.apiCallBegan.type) return next(action);

    const {
      onStart,
      onSuccess,
      onSuccessPayload,
      onError,
      timeout,
      label,
      useFetch,
    } = action.payload;

    let { url, method, data, baseUrl } = action.payload;
    method = method?.toUpperCase() || "GET";

    if (onStart) dispatch({ type: onStart });

    next(action);

    if (method === "GET" && data) {
      url = appendQueryTOGetUrl(url, data);
      data = null;
    }

    try {
      const response = await axios({
        url,
        method,
        data,
        timeout,
        baseUrl,
      });
      // General
      dispatch(actions.apiCallSuccess(response.data));
      // Specific
      if (onSuccess) {
        let payload = response.data;
        if (onSuccessPayload) {
          payload = { ...payload, ...onSuccessPayload };
        }
        dispatch({ type: onSuccess, payload });
        // if (successCallback) {
        //   successCallback(response.data);
        // }
      }
    } catch (error) {
      if (error.response?.data?.isGeneralError) {
        // General
        dispatch(
          actions.apiCallFailed({
            message: error.response?.data?.err?.msg,
            label,
          })
        );
      }

      // Specific
      if (onError) {
        dispatch({
          type: onError,
          payload: error.message,
          errors: error.response?.data?.err?.errorsMap || {}
        });
      }
    }
  };

export default api;
