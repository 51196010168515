import { createSlice } from "@reduxjs/toolkit";
import initialState from "../initialState";
import { apiCallBegan } from "../api";
import { toast } from "react-toastify";

const devicesSlice = createSlice({
  name: "devices",
  initialState: initialState.devices,
  reducers: {
    searchDevicesStart(state) {
      state.lslDevices = [];
      state.idunDevices = [];
      state.loading = true;
      state.error = null;
    },

    searchLSLDevicesSuccess(state, action) {
      state.lslDevices = action.payload.devices;
      state.loading = false;
    },

    searchIDUNDevicesSuccess(state, action) {
      state.idunDevices = action.payload.devices;
      state.loading = false;
    },

    searchDevicesFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    connectDeviceStart(state) {
      state.loading = true;
      state.error = null;
    },

    connectDeviceSuccess(state, action) {
      state.loading = false;
      state.connectedDevice = action.payload.device;

      toast.info(action.payload.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    },

    connectDeviceFailure(state, action) {
      state.loading = false;
      state.error = action.payload;

      const {payload: errorMessage, errors } = action;

      const isNetworkError = errorMessage === "Network Error";

      const message = isNetworkError
        ? "Device connection failed: Cold not connect to device, please ensure widget and device are running."
        : `Device connection failed: ${errors || errorMessage}`;
    
      toast.error(message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
        autoClose: 7500

      });
    },

    disconnectDeviceStart(state) {
      state.error = null;
    },
    disconnectDeviceSuccess(state, action) {
      state.connectedDevice = null;
      state.isStreaming = false;
      state.error = null;
      state.loading = false;
    },
    disconnectDeviceFailure(state, action) {},

    getDeviceStatusStart(state, action) {},

    getDeviceStatusSuccess(state, action) {
      state.connectedDevice = action.payload.device;
      state.isStreaming = action.payload.isStreaming;
    },
    
    getDeviceStatusFailure(state, action) {
      state.connectedDevice = null;
    },


    startStreamStart(state) {
      state.loading = true;
      state.error = null;
    },
    startStreamSuccess(state) {
      state.isStreaming = true;
      state.loading = false;
    },
    startStreamFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    stopStreamStart(state) {
      state.loading = true;
      state.error = null;
    },
    stopStreamSuccess(state) {
      state.isStreaming = false;
      state.loading = false;
    },
    stopStreamFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    pauseStreamStart(state) {
      state.loading = true;
      state.error = null;
    },
    pauseStreamSuccess(state) {
      state.loading = false;
    },
    pauseStreamFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    resumeStreamStart(state) {
      state.loading = true;
      state.error = null;
    },
    resumeStreamSuccess(state) {
      state.loading = false;
    },
    resumeStreamFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    resetDevices(state) {
      state.lslDevices = [];
      state.idunDevices = [];
      state.connectedDevice = null;
    },
  },
});

export const {
  searchDevicesStart,
  searchLSLDevicesSuccess,
  searchIDUNDevicesSuccess,
  searchDevicesFailure,
  connectDeviceStart,
  connectDeviceSuccess,
  connectDeviceFailure,
  disconnectDeviceStart,
  disconnectDeviceSuccess,
  disconnectDeviceFailure,
  getDeviceStatusStart,
  getDeviceStatusSuccess,
  getDeviceStatusFailure,
  startStreamStart,
  startStreamSuccess,
  startStreamFailure,
  stopStreamStart,
  stopStreamSuccess,
  stopStreamFailure,
  pauseStreamStart,
  pauseStreamSuccess,
  pauseStreamFailure,
  resumeStreamStart,
  resumeStreamSuccess,
  resumeStreamFailure,
  resetDevices,
} = devicesSlice.actions;

export default devicesSlice.reducer;

export const searchLSLDevices = () => async (dispatch) => {
    dispatch(
        apiCallBegan({
            url: "/devices/lsl",
            onStart: searchDevicesStart.type,
            onSuccess: searchLSLDevicesSuccess.type,
            onError: searchDevicesFailure.type,
            baseUrl: process.env.REACT_APP_WIDGET_FLASK_API_URL,
        })
    );
};

export const searchIDUNDevices = () => async (dispatch) => {
    dispatch(
        apiCallBegan({
            url: "/devices/idun",
            onStart: searchDevicesStart.type,
            onSuccess: searchIDUNDevicesSuccess.type,
            onError: searchDevicesFailure.type,
            baseUrl: process.env.REACT_APP_WIDGET_FLASK_API_URL,
        })
    );
};

export const connectDevice = (data) => async (dispatch) => {
  dispatch(
      apiCallBegan({
          url: "/devices/connect",
          method: "POST",
          data,
          onStart: connectDeviceStart.type,
          onSuccess: connectDeviceSuccess.type,
          onError: connectDeviceFailure.type,
          baseUrl: process.env.REACT_APP_WIDGET_FLASK_API_URL,
      })
  );
};

export const disconnectDevice = () => async (dispatch) => {
  dispatch(
      apiCallBegan({
          url: "/devices/disconnect",
          method: "POST",
          onStart: disconnectDeviceStart.type,
          onSuccess: disconnectDeviceSuccess.type,
          onError: disconnectDeviceFailure.type,
          baseUrl: process.env.REACT_APP_WIDGET_FLASK_API_URL,
      })
  );
};

export const getDeviceStatus = () => async (dispatch) => {
  dispatch(
      apiCallBegan({
          url: "/status",
          onStart: getDeviceStatusStart.type,
          onSuccess: getDeviceStatusSuccess.type,
          onError: getDeviceStatusFailure.type,
          baseUrl: process.env.REACT_APP_WIDGET_FLASK_API_URL,
      })
  );
};

export const startStream = () => async (dispatch) => {
  dispatch(
    apiCallBegan({
      url: "/stream/start",
      method: "POST",
      onStart: startStreamStart.type,
      onSuccess: startStreamSuccess.type,
      onError: startStreamFailure.type,
      baseUrl: process.env.REACT_APP_WIDGET_FLASK_API_URL,
    })
  );
};

export const stopStream = () => async (dispatch) => {
  dispatch(
    apiCallBegan({
      url: "/stream/stop",
      method: "POST",
      onStart: stopStreamStart.type,
      onSuccess: stopStreamSuccess.type,
      onError: stopStreamFailure.type,
      baseUrl: process.env.REACT_APP_WIDGET_FLASK_API_URL,
    })
  );
};

export const pauseStream = () => async (dispatch) => {
  dispatch(
    apiCallBegan({
      url: "/stream/pause",
      method: "POST",
      onStart: pauseStreamStart.type,
      onSuccess: pauseStreamSuccess.type,
      onError: pauseStreamFailure.type,
      baseUrl: process.env.REACT_APP_WIDGET_FLASK_API_URL,
    })
  );
};

export const resumeStream = () => async (dispatch) => {
  dispatch(
    apiCallBegan({
      url: "/stream/resume",
      method: "POST",
      onStart: resumeStreamStart.type,
      onSuccess: resumeStreamSuccess.type,
      onError: resumeStreamFailure.type,
      baseUrl: process.env.REACT_APP_WIDGET_FLASK_API_URL,
    })
  );
};
