import { useState, useEffect } from "react";
import IconButton from "../common/iconButton";
import CreateTaskModal from "../createTaskModal";
import CreateSubjectModal from "../createSubjectModal";
import CreateProjectModal from "../createProjectModal";
import { PROJECT, SUBJECT, TASK } from "../../utils/constants";

const AddNewItem = ({
  mode,
  source,
  onSubmit,
  extraInfo,
  className,
  loading = false,
}) => {
  const [show, setShow] = useState(false);

  const handleSubmit = (values) => {
    onSubmit(values);
    // if (
    //   source === TASK &&
    //   ["zip", "rar"].includes(
    //     values.get("timeSeriesData").name.split(".").at(-1)
    //   )
    // )
    //   return;
    setShow(false);
  };

  useEffect(() => {
    if (source === TASK && !loading) setShow(false);
  }, [loading]);

  const CreateModal =
    source === PROJECT
      ? CreateProjectModal
      : source === SUBJECT
        ? CreateSubjectModal
        : CreateTaskModal;

  return (
    <>
      <IconButton
        id={`${source}-add`}
        icon="add"
        className={className}
        onClick={() => setShow(true)}
        title={`Create ${source === PROJECT
            ? "project"
            : source === SUBJECT
              ? "subject"
              : "task"
          }`} />

      <CreateModal
        show={show}
        handleClose={() => setShow(false)}
        handleSubmit={handleSubmit}
        mode={mode}
        extraInfo={extraInfo}
        loading={loading}
      />
    </>
  );
};

export default AddNewItem;
