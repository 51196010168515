import React from "react";
import { Button } from "react-bootstrap";
import { getDownloadLink } from "../../utils/taskUtils";

const ExportDataMenu = ({ task }) => {

  function downloadData(task, type) {
    if (!task) {
      const path = window.location.pathname;
      const match = path.match(/\/task\/([^/]+)/);
      task = match ? match[1] : null;
    }

    if (!task) {
        console.error("Task ID could not be determined.");
        return;
    }

    const downloadLink = getDownloadLink(task, type);

    const anchor = document.createElement('a');
    anchor.href = downloadLink;

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }

  return (
    <div>
      <h4>
        <Button             
            variant="outline-light ms-1"
            size="sm"
            onClick={() => downloadData(task, "RAW")}>
          Download Raw Data
        </Button>
      </h4>

      <hr />

      <h4>
        <Button
            variant="outline-light ms-1"
            size="sm"
            onClick={() => downloadData(task, "FILTER")}>
          Download Filter Data
        </Button>
      </h4>

      <hr />

      <h4>
        <Button
            variant="outline-light ms-1"
            size="sm"
            onClick={() => downloadData(task, "INSIGHT")}>
          Download Insight Data
        </Button>
      </h4>

      <hr />

      <h4>
        <Button
            variant="outline-light ms-1"
            size="sm"
            onClick={() => downloadData(task, "ALERT")}>
          Download Alert Data
        </Button>
      </h4>
    </div>
  );
};

export default ExportDataMenu;