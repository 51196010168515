import { useState } from "react";
import { EDIT } from "../../utils/constants";
import { withTranslation } from "react-i18next";
import IconButton from "../common/iconButton";
import CreateProjectModal from "../createProjectModal";
import ConfirmRemoveModal from "../confirmRemoveModal";

const EditProject = ({ item: project, editProject, deleteProject, t }) => {
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const handleDeleteButtonClick = () => {
    setShow(false);
    setShowConfirm(true);
  };

  const handleSubmit = (values) => {
    setShow(false);
    editProject({ ...values, projectId: project.objectId });
  };

  const handleDelete = (a) => {
    setShowConfirm(false);
    deleteProject(project.objectId);
  };

  return (
    <>
      <IconButton
        id={`edit-project-${project.objectId}`}
        onClick={() => setShow(true)}
        icon="pen"
        size="small"
      />
      <CreateProjectModal
        show={show}
        project={project}
        mode={EDIT}
        handleDelete={handleDeleteButtonClick}
        handleClose={() => setShow(false)}
        handleSubmit={handleSubmit}
        extraInfo={{ projectId: project.objectId }}
      />
      <ConfirmRemoveModal
        show={showConfirm}
        text={t("removeProjectConfirmText")}
        handleClose={() => setShowConfirm(false)}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default withTranslation()(EditProject);