import { connect } from "react-redux";
import TaskDetails from "./taskDetails";
import { getTaskDetails } from "../../store/slices/tasks";

function mapStateToProps(state) {
  const { currentTask } = state.entities.tasks;
  return { currentTask };
}

function mapDispatchToProps(dispatch) {
  return {
    getTaskDetails: (taskId) => dispatch(getTaskDetails(taskId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskDetails);
