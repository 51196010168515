import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import TaskControls from "../task/taskControls";
import TaskDetailsHeader from "./taskDetailsHeader";
import { TaskState } from "../taskChartCommon/taskState";
import { getTaskDetails } from "../../store/slices/tasks";
import { getSubjectDetails } from "../../store/slices/subjects";
import { getProjectDetails } from "../../store/slices/projects";
import SubjectDetailsHeader from "../subjectsList/subjectDetailsHeader";
import ProjectDetailsHeader from "../projectsList/projectDetailsHeader";

const TaskDetails = ({
  getProjectDetails,
  getSubjectDetails,
  getTaskDetails,
  currentProject,
  currentSubject,
  currentTask,
}) => {
  const { projectId, subjectId, taskId } = useParams();

  useEffect(() => {
    getProjectDetails(projectId);
    getSubjectDetails(subjectId);
    getTaskDetails(taskId);
    console.log("getting task details form component...")
  }, [
    getProjectDetails,
    getSubjectDetails,
    getTaskDetails,
    projectId,
    subjectId,
    taskId
  ]);

  return (
    <>
      <ProjectDetailsHeader project={currentProject} href="/dashboard/projects/" />
      <SubjectDetailsHeader
        subject={currentSubject}
        project={currentProject}
        subjectsHref={`/dashboard/projects/project/${currentProject?.objectId}/`}
        tasksHref={`/dashboard/projects/project/${currentProject?.objectId}/subject/${currentSubject?.objectId}/`}
        className="mt-52"
      />
      <TaskDetailsHeader task={currentTask?.task} />

      {(currentTask?.task.state !== TaskState.COMPLETED &&
        <TaskControls task={currentTask?.task} />
      )}
    </>
  );
};

function mapStateToProps(state) {
  const { currentProject } = state.entities.projects;
  const { currentSubject } = state.entities.subjects;
  const { currentTask } = state.entities.tasks;

  return {
    currentProject,
    currentSubject,
    currentTask,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProjectDetails: (projectId) => dispatch(getProjectDetails(projectId)),
    getSubjectDetails: (subjectId) => dispatch(getSubjectDetails(subjectId)),
    getTaskDetails: (taskId) => dispatch(getTaskDetails(taskId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskDetails);
