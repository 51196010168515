import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { searchLSLDevices, searchIDUNDevices, connectDevice, disconnectDevice } from "../../store/slices/devices";
import { Modal, Button } from "react-bootstrap";

const DeviceSelectModal = ({ 
    show, 
    handleClose, 
    searchLSLDevices, 
    searchIDUNDevices,
    connectDevice,
    disconnectDevice, 
    lslDevices, 
    idunDevices,
    error,
    loading,
    connectedDevice
}) => {
  const [deviceType, setDeviceType] = useState(null);
  const prevConnectedDevice = useRef(null);

  // Reset the state when modal is closed
  useEffect(() => {
    if (!show) {
      setDeviceType(null);
    }
  }, [show]);

  // Close the modal if connectedDevice changes
  useEffect(() => {
    if (!loading && connectedDevice && connectedDevice !== prevConnectedDevice.current) {
      handleClose();
    }
    prevConnectedDevice.current = connectedDevice;
  }, [connectedDevice, loading, handleClose]);

  useEffect(() => {
    if (deviceType) {
      handleDeviceSearch();
    }
  }, [deviceType]);

  const handleDeviceSearch = () => {
    if (deviceType === "LSL") {
      searchLSLDevices();
    } else if (deviceType === "IDUN") {
      searchIDUNDevices();
    } else if (deviceType === "Virtual") {
      // setLoading(false);
    } else if (deviceType === "Bluetooth") {
      // setLoading(false);
    }
  };

  const handleConnect = (deviceId) => {
    if (deviceType) {
      connectDevice({"id": deviceId, "type": deviceType});
    }
  };

  const handleDisconnect = () => {
      disconnectDevice();
  };

  const devices = deviceType === "LSL" ? lslDevices : idunDevices;

  return (
    <Modal
      centered
      size="lg"
      show={show}
      onHide={() => {
        setDeviceType(null); // Reset state on close
        handleClose();
      }}
      className="device-select-modal"
    >
      <Modal.Header className="p-0" closeButton closeVariant="white">
        <Modal.Title className="text-white">Select and Connect a Device</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 mt-4">
        {connectedDevice && (
          <div className="connected-device-info text-white mb-3 p-0">
            <div className="d-flex align-items-center justify-content-between">
              <p className="mb-0 me-3">
                <strong>Currently Connected:</strong> {connectedDevice.deviceName}
              </p>
              <Button onClick={() => handleDisconnect()} className="btn btn-secondary btn-sm">
                Disconnect
              </Button>
            </div>
          </div>
        )}
        {!deviceType ? (
          <div className="device-type-selection d-grid gap-2">
            <Button onClick={() => setDeviceType("LSL")} className="device-button">
              LSL Device
            </Button>
            <Button onClick={() => setDeviceType("IDUN")} className="device-button">
              IDUN Device
            </Button>
            <Button onClick={() => setDeviceType("Bluetooth")} className="device-button">
              Bluetooth Device
            </Button>
            <Button onClick={() => setDeviceType("Virtual")} className="device-button">
              Virtual Device
            </Button>
          </div>
        ) : (
          <div className="device-list text-white">
            {loading ? (
              <p>Loading devices...</p>
            ) : error ? (
              <div className="error-message text-white">
                <p>Connection failed. Please ensure desktop widget is running and your EEG device is connected.</p>
              </div>
            ) : devices.length > 0 ? (
              devices.map((device, index) => (
                <div key={index} className="device-item d-flex justify-content-between align-items-center text-white">
                  <span>{device.name} - ({device.sample_rate}) Hz</span>
                  <Button 
                    className="connect-button"
                    onClick={() => handleConnect(device.id)}
                  >
                    Connect
                  </Button>
                </div>
              ))
            ) : (
              <div className="no-devices text-white">
                <p>No devices found.</p>
              </div>
            )}
            <div className="row row-cols-sm-2 mt-4">
              <div className="col">
                <Button onClick={() => setDeviceType(null)} className="btn btn-secondary w-100">
                  Back
                </Button>
              </div>
              <div className="col">
                {!loading && (error || devices.length === 0) && (
                  <Button onClick={handleDeviceSearch} className="btn btn-primary w-100">
                    Retry
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  lslDevices: state.entities.devices.lslDevices,
  idunDevices: state.entities.devices.idunDevices,
  error: state.entities.devices.error,
  loading: state.entities.devices.loading,
  connectedDevice: state.entities.devices.connectedDevice,
});

const mapDispatchToProps = (dispatch) => ({
  searchLSLDevices: () => dispatch(searchLSLDevices()),
  searchIDUNDevices: () => dispatch(searchIDUNDevices()),
  connectDevice: (data) => dispatch(connectDevice(data)),
  disconnectDevice: () => dispatch(disconnectDevice()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceSelectModal);
