import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { SUBJECT } from "../utils/constants";
import { useEffect, useState, Children } from "react";
import SubjectsList from "./../components/subjectsList";
import { createSubject } from "../store/slices/subjects";
import IconButton from "../components/common/iconButton";
import ItemsContainer from "../components/itemsContainer";
import AddNewItem from "../components/listItem/addNewItem";
import { getProjectDetails } from "../store/slices/projects";
import ProjectDetailsHeader from "../components/projectsList/projectDetailsHeader";

const SubjectsListPage = ({
  currentProject,
  createSubject,
  getProjectDetails,
}) => {
  const { projectId } = useParams();
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);

  useEffect(() => {
    getProjectDetails(projectId);
  }, [
    projectId,
    getProjectDetails,
  ]);

  return (
    <>
      <ProjectDetailsHeader project={currentProject} href="/dashboard/projects/" />

      <ItemsContainer
        title="Subjects"
        className="mt-52"
        actionButtons={
          Children.toArray([
            <IconButton
              icon="sliders"
              onClick={() => setIsOptionsVisible(!isOptionsVisible)}
            />,
            <AddNewItem
              source={SUBJECT}
              onSubmit={createSubject}
              extraInfo={{ projectId }}
            />
          ])
        }
      >
        <SubjectsList 
          isOptionsVisible={isOptionsVisible}
          indexedFields={["name", "handedness", "age", "gender"]}
        />
      </ItemsContainer>
    </>
  );
};

function mapStateToProps(state) {
  const { currentProject } = state.entities.projects;
  const { list: subjectsList } = state.entities.subjects;

  return {
    currentProject,
    subjectsList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProjectDetails: (projectId) => dispatch(getProjectDetails(projectId)),
    createSubject: (data) => dispatch(createSubject(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SubjectsListPage);
