import { apiCallBegan } from "../api";
import initialState from "../initialState";
import { createSlice } from "@reduxjs/toolkit";
import {
  TASKS_URL as url,
  CREATE_TASK_URL as createUrl,
  DOWNLOAD_TASK_DATA_URL as downloadUrl,
  GET_GCC_URL as getGccUrl,
} from "../constants";
import { toast } from "react-toastify";
import { taskCreatedBySocket, taskUpdatedBySocket } from "../actions/task";

const slice = createSlice({
  name: "tasks",
  initialState: initialState.tasks,
  reducers: {
    tasksRequested: (tasks) => {
      tasks.list = [];
      tasks.loading = true;
    },

    tasksReceived: (tasks, action) => {
      tasks.list = action.payload.result;
      tasks.loading = false;
    },

    tasksFailed: (tasks) => {
      tasks.loading = false;
    },

    taskCreateRequested: (tasks) => {},

    taskCreated: (tasks, action) => {
      toast.info("Task created successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    },

    taskCreateFailed: (tasks, action) => {
      const {payload: errorMessage, errors } = action;

      const isNetworkError = errorMessage === "Network Error";

      const message = isNetworkError
        ? "Task creation failed: Cold not connect to widget, please ensure widget is running."
        : `Task creation failed: ${errors || errorMessage}`;
    
      toast.error(message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
        autoClose: 7500

      });
    },

    taskChartSelected: (state, action) => {
      state.activeChart = action.payload.chartType;
    },

    taskChartPresetSelected: (state, action) => {
      state.activeChartPreset = action.payload.chartPreset;
    },

    taskEditRequested: (tasks) => {},

    taskEdited: (tasks, action) => {
      const updatedTaskIndex = tasks.list.findIndex((task) => task.objectId === action.payload.result.task.objectId);

      if (updatedTaskIndex !== -1) {
        tasks.list[updatedTaskIndex] = action.payload.result.task;
      }

      toast.info("Task edited successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    },

    taskEditFailed: (tasks) => {},

    taskDeleteRequested: (tasks) => {},

    taskDeleted: (tasks, action) => {
      tasks.list = tasks.list.filter((task) => task.objectId !== action.payload.result.objectId);
      
      toast.info("Task deleted successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    },

    taskDeleteFailed: (tasks) => {},

    currentTaskRequested: (tasks) => {},

    currentTaskReceived: (tasks, action) => {
      tasks.currentTask = action.payload.result;
    },

    currentTaskFailed: (tasks) => {},

    tasksDetailsRequested: (tasks) => {},

    tasksDetailsReceived: (state, action) => {
      const tasks = [...state.list];
      const updatedTask = action.payload.result.task; 
      const updatedTaskIndex = tasks.findIndex((task) => task.objectId === updatedTask.objectId);

      if (updatedTaskIndex !== -1) {
        tasks[updatedTaskIndex] = updatedTask;
        state.list = tasks;
      }

      state.currentTask = action.payload.result;
      state.loading = false;
    },

    tasksDetailsFailed: (subjects) => {
      subjects.loading = false;
    },

    dataDownloaded: (tasks, action) => {
      toast.info("Task downloaded successfully",
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(taskCreatedBySocket, (state, action) => {
        const taskIndex = state.list.findIndex((item) => item.objectId === action.payload.task.objectId)

        if (taskIndex !== -1) {
          return;
        }

        state.list = [...state.list, action.payload.task];
      })
      .addCase(taskUpdatedBySocket, (state, action) => {
        const { objectId, ...taskData } = action.payload.data;
        const task = state.list.find((task) => task.objectId === objectId);
        
        if (!task) {
          return;
        }

        const updatedTask = { ...task, ...taskData };

        if (state.currentTask && state.currentTask.task && state.currentTask.task.objectId === task.objectId) {
          state.currentTask.task = updatedTask;
        }

        state.list = [
          ...state.list.filter((task) => task.objectId !== objectId),
          updatedTask,
        ];
      })
  }
});

const {
  tasksRequested,
  tasksReceived,
  tasksFailed,

  taskCreateRequested,
  taskCreated,
  taskCreateFailed,

  taskEditRequested,
  taskEdited,
  taskEditFailed,

  taskDeleteRequested,
  taskDeleted,
  taskDeleteFailed,

  tasksDetailsRequested,
  tasksDetailsReceived,
  tasksDetailsFailed,

  taskChartSelected,
  taskChartPresetSelected,

  dataDownloaded,
} = slice.actions;
export default slice.reducer;

export const getTasks = (subjectId) => (dispatch) => {
  dispatch(
    apiCallBegan({
      url: `${url}/${subjectId}`,
      onStart: tasksRequested.type,
      onSuccess: tasksReceived.type,
      onError: tasksFailed.type,
    })
  );
};

export const createTask = (data) => (dispatch) => {
  dispatch(
    apiCallBegan({
      url: createUrl,
      method: "POST",
      data,
      onStart: taskCreateRequested.type,
      onSuccess: taskCreated.type,
      onError: taskCreateFailed.type,
    })
  );
};

export const createTaskWidget = (data) => (dispatch) => {
  dispatch(
    apiCallBegan({
      url: "/tasks/create",
      method: "POST",
      data,
      onStart: taskCreateRequested.type,
      onSuccess: taskCreated.type,
      onError: taskCreateFailed.type,
      baseUrl: process.env.REACT_APP_WIDGET_FLASK_API_URL,
    })
  );
};

export const getGCCUrl = (data, successCallback) => (dispatch) => {
  dispatch(
    apiCallBegan({
      url: getGccUrl,
      method: "POST",
      data,
      onStart: taskCreateRequested.type,
      onSuccess: taskCreated.type,
      successCallback,
      onError: taskCreateFailed.type,
    })
  );
};

export const editTask = (data) => (dispatch) => {
  dispatch(
    apiCallBegan({
      url: `${createUrl}/${data.taskId}`,
      method: "PUT",
      data,
      onStart: taskEditRequested.type,
      onSuccess: taskEdited.type,
      onError: taskEditFailed.type,
    })
  );
};

export const deleteTask = (taskId) => (dispatch) => {
  dispatch(
    apiCallBegan({
      url: `${createUrl}/${taskId}`,
      method: "DELETE",
      onStart: taskDeleteRequested.type,
      onSuccess: taskDeleted.type,
      onError: taskDeleteFailed.type,
    })
  );
};

export const getTaskDetails = (taskId) => (dispatch) => {
  dispatch(
    apiCallBegan({
      url: `${createUrl}/${taskId}`,
      onStart: tasksDetailsRequested.type,
      onSuccess: tasksDetailsReceived.type,
      onError: tasksDetailsFailed.type,
    })
  );
};

export const downloadData = (taskId, type, reduce) => (dispatch) => {
  dispatch(
    apiCallBegan({
      url: `${downloadUrl}/${taskId}/${type}/${reduce}`,
      onStart: tasksDetailsRequested.type,
      onSuccess: dataDownloaded.type,
      onError: tasksDetailsFailed.type,
    })
  );
};

export const selectChart = (chartType) => (dispatch) => {
  dispatch({ type: taskChartSelected.type, payload: { chartType } });
}

export const selectChartPreset = (chartPreset) => dispatch => {
  dispatch({ type: taskChartPresetSelected.type, payload: { chartPreset } });
}

export const appendTask = (data) => (dispatch) => {
  dispatch({ type: taskCreatedBySocket.type, payload: { task: data } });
}

export const patchTask = (data) => (dispatch) => {
  dispatch({ type: taskUpdatedBySocket.type, payload: { data } });
}